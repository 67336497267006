/* eslint-disable @typescript-eslint/indent */
/* istanbul ignore file */
import { ArrowDropDownRounded } from '@mui/icons-material';
import {
  Theme,
  alpha,
  createTheme,
  type ThemeOptions,
} from '@mui/material/styles';

const defaultTheme = createTheme();

declare module '@mui/material/styles/createPalette' {
  interface ColorRange {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteColor extends ColorRange {}

  interface Palette {
    primaryDark: PaletteColor;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    code: true;
    link: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    light: true;
  }
}

/**
 * Style overrides for Material UI components.
 */
export const getThemedComponents = (
  theme: Theme,
): ThemeOptions['components'] => ({
  MuiButtonBase: {
    defaultProps: {
      disableTouchRipple: true,
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      sizeLarge: {
        padding: '0.875rem 1rem',
        ...theme.typography.body1,
        lineHeight: 21 / 16,
        fontWeight: 700,
      },
      sizeSmall: {
        padding: theme.spacing(0.5, 1),
        marginLeft: theme.spacing(-1),
      },
      containedPrimary: {
        backgroundColor: theme.palette.primary[500],
        color: '#fff',
      },
    },
    variants: [
      {
        props: { variant: 'code' },
        style: {
          color:
            theme.palette.mode === 'dark'
              ? theme.palette.grey[400]
              : theme.palette.grey[800],
          border: '1px solid',
          borderColor:
            theme.palette.mode === 'dark'
              ? theme.palette.primaryDark[400]
              : theme.palette.grey[300],
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.primaryDark[700]
              : theme.palette.grey[50],
          fontFamily: theme.typography.fontFamilyCode,
          fontWeight: 400,
          fontSize: defaultTheme.typography.pxToRem(13), // 14px
          lineHeight: 21 / 14,
          letterSpacing: 0,
          WebkitFontSmoothing: 'subpixel-antialiased',
          '&:hover, &.Mui-focusVisible': {
            borderColor: theme.palette.primary.main,
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.primaryDark[600]
                : theme.palette.primary[50],
            '& .MuiButton-endIcon': {
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.primary[300]
                  : theme.palette.primary.main,
            },
          },
          '& .MuiButton-startIcon': {
            color: theme.palette.grey[400],
          },
          '& .MuiButton-endIcon': {
            display: 'inline-block',
            position: 'absolute',
            right: 0,
            marginRight: 10,
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.grey[400]
                : theme.palette.grey[700],
          },
        },
      },
      {
        props: { variant: 'link' },
        style: {
          fontSize: theme.typography.pxToRem(14),
          fontWeight: 700,
          color:
            theme.palette.mode === 'dark'
              ? theme.palette.primary[300]
              : theme.palette.primary[600],
          mb: 1,
          '& svg': {
            ml: -0.5,
          },
        },
      },
    ],
  },
  MuiIconButton: {
    variants: [
      {
        props: { color: 'primary' },
        style: {
          height: 34,
          width: 34,
          border: `1px solid ${
            theme.palette.mode === 'dark'
              ? theme.palette.primaryDark[700]
              : theme.palette.grey[200]
          }`,
          borderRadius: theme.shape.borderRadius,
          color:
            theme.palette.mode === 'dark'
              ? theme.palette.primary[300]
              : theme.palette.primary[500],
          '&:hover': {
            borderColor:
              theme.palette.mode === 'dark'
                ? theme.palette.primaryDark[600]
                : theme.palette.grey[300],
            background:
              theme.palette.mode === 'dark'
                ? alpha(theme.palette.primaryDark[700], 0.4)
                : theme.palette.grey[50],
          },
        },
      },
    ],
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        mt: 0.5,
        minWidth: 160,
        elevation: 0,
        color: theme.palette.text.secondary,
        backgroundImage: 'none',
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.primaryDark[900]
            : theme.palette.background.paper,
        border: `1px solid ${
          theme.palette.mode === 'dark'
            ? theme.palette.primaryDark[700]
            : theme.palette.grey[200]
        }`,
        '& .MuiMenuItem-root': {
          fontSize: theme.typography.pxToRem(14),
          fontWeight: 500,
          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? alpha(theme.palette.primaryDark[700], 0.4)
                : theme.palette.grey[50],
          },
          '&:focus': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? alpha(theme.palette.primaryDark[700], 0.4)
                : theme.palette.grey[50],
          },
          '&.Mui-selected': {
            fontWeight: 500,
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.primary[300]
                : theme.palette.primary[600],
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.primaryDark[700]
                : alpha(theme.palette.primary[100], 0.6),
          },
        },
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        boxShadow: `0px 4px 20px ${
          theme.palette.mode === 'dark'
            ? 'rgba(0, 0, 0, 0.5)'
            : 'rgba(170, 180, 190, 0.3)'
        }`,
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        [theme.breakpoints.up('md')]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor:
          theme.palette.mode === 'dark'
            ? alpha(theme.palette.primary[100], 0.08)
            : theme.palette.grey[100],
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: 'none',
    },
    styleOverrides: {
      root: {
        color:
          theme.palette.mode === 'dark'
            ? theme.palette.primary[300]
            : theme.palette.primary[600],
        fontWeight: 700,
        display: 'inline-flex',
        alignItems: 'center',
        '&:hover': {
          color:
            theme.palette.mode === 'dark'
              ? theme.palette.primary[200]
              : theme.palette.primary[700],
        },
        '&.MuiTypography-body1 > svg': {
          marginTop: 2,
        },
        '& svg:last-child': {
          marginLeft: 2,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: ({ ownerState: { color, variant } }) => ({
        fontWeight: 500,
        ...(variant === 'outlined' &&
          color === 'default' && {
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.grey[300]
                : theme.palette.grey[900],
            backgroundColor: 'transparent',
            borderColor:
              theme.palette.mode === 'dark'
                ? alpha(theme.palette.grey[100], 0.1)
                : theme.palette.grey[200],
          }),
        ...(variant === 'filled' &&
          color === 'default' && {
            border: '1px solid transparent',
            color:
              theme.palette.mode === 'dark'
                ? '#fff'
                : theme.palette.primary[800],
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.primaryDark[500]
                : theme.palette.primary[100],
            '&:hover': {
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.primaryDark[600]
                  : theme.palette.primary[200],
            },
          }),
        ...(variant === 'light' && {
          ...(color === 'default' && {
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.primary[200]
                : theme.palette.primary[700],
            backgroundColor:
              theme.palette.mode === 'dark'
                ? alpha(theme.palette.primaryDark[700], 0.5)
                : alpha(theme.palette.primary[100], 0.3),
          }),
          ...(color === 'warning' && {
            color:
              theme.palette.mode === 'dark'
                ? '#fff'
                : theme.palette.warning[900],
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.warning[900]
                : theme.palette.warning[100],
          }),
          ...(color === 'success' && {
            color:
              theme.palette.mode === 'dark'
                ? '#fff'
                : theme.palette.success[900],
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.success[900]
                : theme.palette.success[100],
          }),
        }),
      }),
      deleteIcon: {
        color:
          theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary[700],
        '&:hover': {
          color:
            theme.palette.mode === 'dark'
              ? theme.palette.grey[100]
              : theme.palette.primary[900],
        },
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        padding: '8px',
        textTransform: 'none',
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(14),
        color:
          theme.palette.mode === 'dark'
            ? theme.palette.grey[300]
            : theme.palette.grey[700],
        borderRadius: 0,
        '&:hover': {
          backgroundColor:
            theme.palette.mode === 'dark'
              ? alpha(theme.palette.primaryDark[700], 0.4)
              : theme.palette.grey[50],
        },
        '&.Mui-selected': {
          color:
            theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary[500],
          borderRadius: 10,
          border: '1px solid',
          borderColor:
            theme.palette.mode === 'dark'
              ? `${theme.palette.primary[700]} !important`
              : `${theme.palette.primary[500]} !important`,
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.primaryDark[700]
              : theme.palette.primary[50],
          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.primaryDark[600]
                : theme.palette.primary[100],
          },
        },
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      IconComponent: ArrowDropDownRounded,
    },
    styleOverrides: {
      iconFilled: {
        top: 'calc(50% - .25em)',
      },
    },
  },
  MuiTab: {
    defaultProps: {
      disableTouchRipple: true,
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'none',
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.primaryDark[900]
            : '#fff',
        '&[href]': {
          textDecorationLine: 'none',
        },
      },
      outlined: {
        display: 'block',
        borderColor:
          theme.palette.mode === 'dark'
            ? theme.palette.primaryDark[500]
            : theme.palette.grey[200],
        ...(theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.primaryDark[700],
        }),
        'a&, button&': {
          '&:hover': {
            boxShadow: `0px 4px 20px ${
              theme.palette.mode === 'dark'
                ? 'rgba(0, 0, 0, 0.5)'
                : 'rgba(170, 180, 190, 0.3)'
            }`,
          },
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: theme.spacing(1, 2),
        borderColor: theme.palette.divider,
      },
      head: {
        color: theme.palette.text.primary,
        fontWeight: 700,
      },
      body: {
        color: theme.palette.text.secondary,
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.primaryDark[900]
            : '#fff',
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontWeight: 500,
        color:
          theme.palette.mode === 'dark'
            ? theme.palette.grey[300]
            : theme.palette.grey[700],
        borderColor:
          theme.palette.mode === 'dark'
            ? theme.palette.primaryDark[500]
            : theme.palette.grey[200],
        '&.Mui-selected': {
          color:
            theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary[500],
          borderColor:
            theme.palette.mode === 'dark'
              ? `${theme.palette.primary[700]} !important`
              : `${theme.palette.primary[500]} !important`,
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.primaryDark[700]
              : theme.palette.primary[50],
          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.primaryDark[600]
                : theme.palette.primary[100],
          },
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        padding: '5px 9px',
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: 32,
        height: 20,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          '&.Mui-checked': {
            transform: 'translateX(11px)',
            color: '#fff',
          },
        },
      },
      switchBase: {
        height: 20,
        width: 20,
        padding: 0,
        color: '#fff',
        '&.Mui-checked + .MuiSwitch-track': {
          opacity: 1,
        },
      },
      track: {
        opacity: 1,
        borderRadius: 32,
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.grey[800]
            : theme.palette.grey[400],
      },
      thumb: {
        flexShrink: 0,
        width: '14px',
        height: '14px',
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontWeight: 700,
        color:
          theme.palette.mode === 'dark'
            ? theme.palette.grey[300]
            : theme.palette.grey[700],
        borderColor:
          theme.palette.mode === 'dark'
            ? theme.palette.primaryDark[500]
            : theme.palette.grey[200],
        '&.Mui-selected': {
          color:
            theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary[500],
          borderColor:
            theme.palette.mode === 'dark'
              ? `${theme.palette.primary[700]} !important`
              : `${theme.palette.primary[500]} !important`,
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.primaryDark[700]
              : theme.palette.primary[50],
          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.primaryDark[600]
                : theme.palette.primary[100],
          },
        },
      },
    },
  },
  MuiCssBaseline: {
    defaultProps: {
      enableColorScheme: true,
    },
  },
});
