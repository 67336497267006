import { createTheme, ThemeOptions } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { getThemedComponents } from './components';
import { blueDark, getPalette, grey } from './palettes';
import { getTypography } from './typography';

const getDesignTokens = (mode: 'light' | 'dark') =>
  ({
    palette: getPalette(mode),
    shape: {
      borderRadius: 10,
    },
    spacing: 10,
    typography: getTypography(mode),
  } as ThemeOptions);

export const getMetaThemeColor = (mode: 'light' | 'dark') => {
  const themeColor = {
    light: grey[50],
    dark: blueDark[800],
  };
  return themeColor[mode];
};

const lightTheme = createTheme(getDesignTokens('light'));
export const brandingLightTheme = deepmerge(
  lightTheme,
  getThemedComponents(lightTheme),
);

const darkTheme = createTheme(getDesignTokens('dark'));
export const brandingDarkTheme = deepmerge(
  darkTheme,
  getThemedComponents(darkTheme),
);
