import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { BaseLayout } from '../pages/BaseLayout';
import { ErrorPage } from '../pages/ErrorPage';

const HomePage = lazy(() => import('../pages/HomePage'));
const ViewerPage = lazy(() => import('../pages/ViewerPage'));

/**
 * Application routes
 * https://reactrouter.com/en/main/routers/create-browser-router
 */
export const router = createBrowserRouter([
  {
    path: '',
    element: <BaseLayout />,
    errorElement: <ErrorPage />,
    children: [{ index: true, element: <HomePage /> }],
  },
  {
    path: ':starId',
    element: <BaseLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <ViewerPage /> },
      { path: 'edit', element: <div>Skych Editor</div> },
      { path: 'inspect', element: <div>Artist Mode</div> },
    ],
  },
]);
