import { Container, Typography } from '@mui/material';
import { useRouteError } from 'react-router-dom';

export function ErrorPage() {
  const error = useRouteError() as RouteError;
  return (
    <Container sx={{ marginTop: '43vh' }} maxWidth="sm">
      <Typography
        sx={{
          fontSize: '2em',
          fontWeight: 300,
          '& strong': { fontWeight: 400 },
        }}
        variant="h1"
        align="center"
      >
        <strong>Error {error.status || 500}</strong>:{' '}
        {error.statusText ?? error.message}
      </Typography>
    </Container>
  );
}

type RouteError = Error & { status?: number; statusText?: string };
