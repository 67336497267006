import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { FirebaseProvider } from './providers/FirebaseProvider';
import reportWebVitals from './reportWebVitals';
import { router } from './routes';
import { brandingLightTheme } from './theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={brandingLightTheme}>
      <CssBaseline />
      <FirebaseProvider>
        <RouterProvider router={router} />
      </FirebaseProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
